import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import { useGetJobsQuery } from '@apis/mediacore/jobs'
import { makeItemsNav } from '@helpers/helper.rendering'
import useQuerySummary from '@hooks/useQuerySummary'
import React, { useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import { Division, RealEstateDataHeaderData, RealEstateDataPage } from 'types'
import { useLocation } from '@reach/router'

interface ExternalProps {
  pages?: RealEstateDataPage[]
  development_id: string
  property_id: string
  novelty_id: string
  three: Division[]
}

type CombinedProps = RealEstateDataHeaderData & ExternalProps

const Header_6 = (props: CombinedProps) => {
  //GlobalData Content
  const { global_data, pages, buttonHeart, three } = props
  const { logo, logoMobile } = global_data
  const { divisionPath, id: siteId } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko

  const isCompiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'

  //Modules
  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { data: allJobsData } = useGetJobsQuery({ site: siteId })
  const { allSummaryData } = useQuerySummary({ API_KEY })

  const [open, setOpen] = useState(false)

  const location = useLocation()

  return (
    <header className="header_6 d-flex align-items-center">
      <Container
        className="h-100"
        fluid
      >
        <Row className="h-100">
          <Col
            className="d-flex h-100"
            lg={3}
            xs={9}
          >
            <div
              style={{ borderRight: '1px solid #fff' }}
              className="logo h-100 d-flex align-items-center pe-lg-4 pe-3"
            >
              <img
                src={logo}
                className="mt-auto mb-auto"
                alt="logo-desktop"
              />
            </div>
            {isCompiler && (
              <div
                style={{ borderRight: '1px solid #fff' }}
                onClick={() => setOpen(true)}
                className="logo h-100 d-flex align-items-center px-3 px-lg-4 text-capitalize cursor-pointer"
              >
                {location.pathname.split('/').at(1) || 'Inicio'}
                <i className="icon-arrow-light"></i>
              </div>
            )}
          </Col>
          <Col
            lg={9}
            xs={3}
            className="d-flex justify-content-end align-items-center"
          >
            {pages ? (
              <nav>
                {makeItemsNav(allSummaryData, allBlogData, pages, allJobsData)
                  .filter((page) => !page.path.includes('detalle'))
                  .map(({ path, title }, index) => (
                    <Link
                      key={index}
                      className={
                        'ms-lg-5 link-nav d-lg-block d-none ' +
                        ((location.pathname.includes(path) &&
                          location.pathname !== '' &&
                          path !== '/') ||
                        (path === '/' && location.pathname === '/') ||
                        (location.pathname.toLowerCase() === divisionPath?.toLowerCase() + '/' &&
                          path === '/')
                          ? 'active'
                          : '')
                      }
                      to={(divisionPath ?? '') + path}
                    >
                      {title}
                    </Link>
                  ))}
                {'buttonHeart?.link' && (
                  <Link
                    className="btn btn-white border btn-favorites ms-5"
                    to={'buttonHeart?.link'}
                  >
                    3<i className="icon-favoritos-heart-light ms-3"></i>
                  </Link>
                )}
              </nav>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>
      <div className={'sidebar ' + (open ? 'open' : '')}>
        <div className="content">
          <div className="header-sidebar d-flex w-100 justify-content-between">
            <h5>{'Bienvenido'}</h5>
            <div
              className="close cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <i></i>
              <i></i>
            </div>
          </div>
          <div className="body-sidebar">
            <div className="current-section py-4">
              <h5>
                {' '}
                <Link to="/">{global_data.name || 'Sitio'}</Link>
              </h5>
            </div>
            {three?.map((element: any, index: number) => (
              <Accordion
                key={index}
                defaultActiveKey={null}
              >
                <Accordion.Item eventKey={element.title}>
                  <Accordion.Header className="collapsed">
                    {' '}
                    <h5>
                      {' '}
                      0{index + 1}. {element.title}
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* {makeItemsNav} */}
                    {makeItemsNav(
                      allSummaryData,
                      allBlogData,
                      element.site?.content.pages,
                      allJobsData,
                    )
                      .filter((page) => !page.path.includes('detalle'))
                      .map(({ path, title }, index) => (
                        // {element.site?.content.pages.map(
                        //   (site: { path: string; title: string }, index: string) => (
                        <li key={index}>
                          <Link to={element.path + path}>{title}</Link>
                        </li>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header_6

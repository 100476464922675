import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from '@reach/router'
import Countdown from 'react-countdown'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { graphql, useStaticQuery } from 'gatsby'
import { informCustomEvent } from '@helpers/helper.analytics'
import { getQueryEventTracker } from '@helpers/helper.actions'
import { ModuleActionProps } from 'types'
import { useCreateApplicationMutation as useSendTrackerEvent } from '@apis/mediacore/tracker'

const CounterPopup = ({
  linkTo,
  linkTxt,
  title,
  text,
  showOn,
  band,
  eventDate,
  utmValue,
  type,
  name,
  action,
  page,
  property,
  development,
}: ModuleActionProps) => {
  const Square = require('./cuadro.svg') as string

  const location = useLocation()
  const pathname = location.pathname.replaceAll('/', '')
  const [sendTrackerEvent] = useSendTrackerEvent()

  const pop: any = useRef()
  const [isOpen, setIsOpen] = useState(false)

  // Timeout to show the component
  useEffect(() => {
    if (checkPage() && !isOpen) {
      if (
        (action.section === 'Detalle de Emprendimiento' && development) ||
        (action.section === 'Detalle de propiedad' && property) ||
        (action.section !== 'Detalle de Emprendimiento' &&
          action.section !== 'Detalle de propiedad')
      ) {
        informCustomEvent(
          'SHOW_' +
            page.id +
            '_' +
            (property && action.section === 'Detalle de propiedad'
              ? property.id.toString()
              : development && action.section === 'Detalle de Emprendimiento'
              ? development.id.toString()
              : '0') +
            '_' +
            action.id,
        )
        sendTrackerEvent(
          getQueryEventTracker({
            page: page,
            action: action,
            type: 'SHOW',
            data_id:
              property && action.section === 'Detalle de propiedad'
                ? property.id.toString()
                : development && action.section === 'Detalle de Emprendimiento'
                ? development.id.toString()
                : '0',
            url: location.href,
            extra_title:
              action.section === 'Detalle de propiedad' && property
                ? property?.address
                : development && action.section === 'Detalle de Emprendimiento'
                ? development.name
                : '',
          }),
        )
        setTimeout(() => {
          pop?.current?.open()
          setIsOpen(true)
        }, 2000)
      }
    }
  }, [pathname, property, development])

  // Check where shows
  const checkPage = () => {
    return true
  }

  // Render countdown
  const rendererCtd = ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: string
    hours: string
    minutes: string
    seconds: string
  }) => (
    <div className="counter d-flex justify-content-center text-center mt-4">
      <div className="mx-2 d-flex flex-column justify-content-center">
        <span className="date-val">{days}</span>
        <span className="date-text">DÍAS</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{hours}</span>
        <span className="date-text">HS</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{minutes}</span>
        <span className="date-text">MIN</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{seconds}</span>
        <span className="date-text">SEG</span>
      </div>
    </div>
  )

  return checkPage() ? (
    <Popup
      ref={pop}
      position="right center"
    >
      <i
        className="icon-plus close"
        role="button"
        id="closePopup"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={() => pop.current.close()}
      ></i>
      {/* <button className="close" > */}
      {/* &times; */}
      {/* </button> */}
      <div className={`content-border ${band ? 'show-band' : 'hide-band'}`}>
        {/* <Square/> */}
        {band && (
          <div className="band">
            <span className="text-band text-center">{band}</span>
          </div>
        )}
        {/* <Star className="star-icon"/> */}
        <div className="star-icon">
          <i className="icon-favoritos-star-solid"></i>
        </div>
        <h1 className="text-center text-uppercase">{title}</h1>
        {text && <p className="popup-text my-3 text-center">{text}</p>}
        {eventDate && (
          <Countdown
            date={(new Date(eventDate!) as any).getTime()}
            //@ts-ignore
            renderer={rendererCtd}
          />
        )}
        {linkTo && (
          <a
            className="d-flex text-center align-items-center justify-content-center text-uppercase"
            onClick={() => {
              informCustomEvent(
                'PUSH_' +
                  page.id +
                  '_' +
                  (property && action.section === 'Detalle de propiedad'
                    ? property.id.toString()
                    : development && action.section === 'Detalle de Emprendimiento'
                    ? development.id.toString()
                    : '0') +
                  '_' +
                  action.id,
              )
              pop.current.close()
              sendTrackerEvent(
                getQueryEventTracker({
                  page: page,
                  action: action,
                  type: 'PUSH',
                  data_id:
                    property && action.section === 'Detalle de propiedad'
                      ? property.id.toString()
                      : development && action.section === 'Detalle de Emprendimiento'
                      ? development.id.toString()
                      : '0',
                  url: location.href,
                  extra_title:
                    action.section === 'Detalle de propiedad' && property
                      ? property?.address
                      : development && action.section === 'Detalle de Emprendimiento'
                      ? development.name
                      : '',
                }),
              )
            }}
            href={linkTo + utmValue}
            target="_blank"
          >
            {linkTxt}
          </a>
        )}
      </div>
    </Popup>
  ) : null
}

export default CounterPopup

// Component usage
// <CounterPopup
//      linkTo={'/servicios'} -> url link
//      linkTxt={'A un click de conocerlo'} -> text link
//      title={'¡Nuevo emprendimiento!'} -> text title
//      showOn={'nosotros'} -> where show | add to components page
//      band={'Proyecto de pozo, texto de prueba'} -> band top right
//      eventDate={'9/15/2022'}/> -> event date

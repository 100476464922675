import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { makeItemsNav } from '@helpers/helper.rendering'
import useQuerySummary from '@hooks/useQuerySummary'
import { Link } from 'gatsby'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import { RealEstateDataHeaderData, RealEstateDataPage } from 'types'
import { useLocation } from '@reach/router'
interface ExternalProps {
  pages: RealEstateDataPage[]
  development_id: string
  property_id: string
  novelty_id: string
}

type CombinedProps = RealEstateDataHeaderData & ExternalProps

const Header5 = (props: CombinedProps) => {
  const { global_data, pages } = props
  const { id: siteId } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko

  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { allSummaryData } = useQuerySummary({ API_KEY })

  const logo = global_data.logo
  const logoMobile = global_data.logoMobile

  const [isMounted, setIsMounted] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen)
  }

  const location = useLocation()

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return isMounted ? (
    <header
      id="header"
      className="header c header d"
    >
      <Navbar className="navbar-sticky py-0">
        <Container style={{ maxWidth: '100%', height: '100%' }}>
          <Row className="w-100 h-100 d-xl-flex d-none border-white">
            <Col
              lg={3}
              xs={6}
              className="d-flex align-items-center justify-content-center px-xl-5"
            >
              <Link
                aria-label="Enlace inicio"
                to="/"
              >
                <img
                  alt="logo-header"
                  src={logo}
                  height={'40vh'}
                  width={'auto'}
                  className="d-lg-inline-block d-none align-top"
                />
              </Link>
            </Col>
            <Col
              lg={6}
              xs={10}
              className="d-flex align-items-center justify-content-center links-column h-100"
            >
              <Nav className="w-100 d-flex justify-content-between">
                {makeItemsNav(allSummaryData, allBlogData, pages, undefined)
                  .filter(
                    (page) => !page.path.includes('detalle') && !page.path.includes('favoritos'),
                  )
                  .filter((page) => page.path !== '/')
                  .map(({ path, title }, index) => (
                    <Fragment key={index}>
                      <Nav.Item className="me-3">
                        {path.includes('venta') ||
                        path.includes('alquiler') ||
                        (path.includes('temporario') && !location.pathname.includes(path)) ? (
                          <a
                            className={
                              (location.pathname.includes(path) &&
                                location.pathname !== '' &&
                                path !== '/') ||
                              (path === '/' && location.pathname === '/')
                                ? 'custom-link active text-nowrap'
                                : 'custom-link text-nowrap'
                            }
                            href={path}
                          >
                            {title}
                          </a>
                        ) : (
                          <Link
                            onClick={() => {
                              scrollTo(0, 0)
                            }}
                            className={
                              (location.pathname.includes(path) &&
                                location.pathname !== '' &&
                                path !== '/') ||
                              (path === '/' && location.pathname === '/')
                                ? 'custom-link active text-nowrap'
                                : 'custom-link text-nowrap'
                            }
                            to={path}
                          >
                            {title}
                          </Link>
                        )}
                      </Nav.Item>
                    </Fragment>
                  ))}
              </Nav>
            </Col>
            <Col
              lg={3}
              xs={6}
              className="d-flex align-items-center justify-content-center px-xl-5 logo-container"
            >
              <Link
                aria-label="Enlace contacto"
                to="/contacto"
                className="logo-link"
              >
                <img
                  alt="logo-header"
                  src={logoMobile}
                  height={'40vh'}
                  width={'auto'}
                  className="d-lg-inline-block d-none align-top logo-image"
                />
                <Link
                  onClick={() => {
                    scrollTo(0, 0)
                  }}
                  className={
                    location.pathname.includes('/contacto') && location.pathname !== ''
                      ? 'custom-link active text-nowrap hover-link'
                      : 'custom-link text-nowrap hover-link'
                  }
                  to={'/contacto'}
                >
                  {'Contacto'}
                </Link>
              </Link>
            </Col>
          </Row>
          <Row className="w-100 h-100 d-flex justify-content-center align-items-center d-xl-none d-block pt-2">
            <Col
              xs={10}
              className="px-0 h-100 d-flex justify-content-center align-items-center"
            >
              <Link
                aria-label="Enlace inicio"
                className="d-xl-block d-flex align-items-center m-0"
                to="/"
              >
                <img
                  alt="logo-header-mobile"
                  src={logo}
                  style={{
                    maxHeight: '60px',
                    maxWidth: '50%',
                  }}
                  className="d-xl-none d-block align-top"
                />
              </Link>
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-center align-items-center"
            >
              <button
                aria-label="toggle"
                className="toggle-nav d-xl-none"
                onClick={handleMenuToggle}
              >
                <div className="d-flex align-items-center">
                  <span>
                    {menuOpen ? (
                      <span className="icon-close-light close-icon" />
                    ) : (
                      <>
                        {' '}
                        <i></i>
                        <i></i>
                        <i></i>
                      </>
                    )}
                  </span>
                </div>
              </button>
            </Col>
          </Row>
        </Container>
      </Navbar>
      {menuOpen && (
        <div className="mobile-menu">
          <Nav className="d-flex flex-column">
            {makeItemsNav(allSummaryData, allBlogData, pages, undefined)
              .filter((page) => !page.path.includes('detalle') && !page.path.includes('favoritos'))
              .map(({ path, title }, index) => (
                <Fragment key={index}>
                  <Nav.Item className="my-3 ms-5">
                    {path.includes('venta') ||
                    path.includes('alquiler') ||
                    (path.includes('temporario') && !location.pathname.includes(path)) ? (
                      <a
                        className={
                          (location.pathname.includes(path) &&
                            location.pathname !== '' &&
                            path !== '/') ||
                          (path === '/' && location.pathname === '/')
                            ? 'custom-link active'
                            : 'custom-link'
                        }
                        href={path}
                        onClick={() => setMenuOpen(false)}
                      >
                        {title}
                      </a>
                    ) : (
                      <Link
                        onClick={() => {
                          scrollTo(0, 0)
                          setMenuOpen(false)
                        }}
                        className={
                          (location.pathname.includes(path) &&
                            location.pathname !== '' &&
                            path !== '/') ||
                          (path === '/' && location.pathname === '/')
                            ? 'custom-link active'
                            : 'custom-link'
                        }
                        to={path}
                      >
                        {title}
                      </Link>
                    )}
                  </Nav.Item>
                </Fragment>
              ))}
          </Nav>
        </div>
      )}
    </header>
  ) : (
    ''
  )
}

export default Header5

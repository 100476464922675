import { getArrayOperationById, makeParamsCoordinates } from '@helpers/helper.filters'
import { middlewareTokkoApi } from '../middlewareTokkoApi'
import { removeDuplicateProperties } from '@helpers/helper.properties'

const propertiesExtended = middlewareTokkoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdvancedLocations: builder.query({
      query: ({ API_KEY, operation = '', types = [] }) => ({
        headers: {
          'X-TOKKO-KEY': API_KEY,
        },
        url: `properties/locations/?operation=${getArrayOperationById(operation)}${
          types.length > 0 ? '&tipo=' + types.map((type: { id: string }) => type.id) : ''
        }`,
        method: 'GET',
      }),
    }),
    getFeaturedProperties: builder.query({
      query: ({ API_KEY }) => ({
        headers: {
          'X-TOKKO-KEY': API_KEY,
        },
        url: `properties/search?cache=1&save=0&limit=60&offset=0&destacados=1`,
        method: 'GET',
      }),
    }),
    getProperties: builder.query({
      query: ({ API_KEY, params_get, coordinates, polygonCoordinates }) => ({
        headers: {
          'X-TOKKO-KEY': API_KEY,
        },
        url: `properties/search${params_get}`,
        body: makeParamsCoordinates(coordinates, polygonCoordinates),
        method: 'POST',
      }),
      transformResponse: ({ meta, objects }) => {
        return { meta, objects: removeDuplicateProperties(objects) }
      },
    }),
    getProperty: builder.query({
      query: ({ API_KEY, ID }) => ({
        headers: {
          'X-TOKKO-KEY': API_KEY,
        },
        url: `properties/${ID}?cache=1`,
        method: 'GET',
      }),
      // transformResponse: ({objects}) => objects,
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAdvancedLocationsQuery,
  useGetFeaturedPropertiesQuery,
  useGetPropertiesQuery,
  useGetPropertyQuery,
  usePrefetch,
} = propertiesExtended
